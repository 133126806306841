<script setup>
import {
  editCases,
  productList,
  PublicProductTypeListByDoctor
} from "common/api/cases";
import { notifyMsg } from "common/js/util";
import ToggleText from "@/components/toggle-text.vue";
import CDate from "common/js/CDate";
import { get } from "lodash";

export default {
  components: {
    ToggleText
  },
  props: {
    details: {
      type: Object
    }
  },
  data() {
    return {
      isResponse: 0,
      key: 1,
      productList: [],
      productDict: {},
      productTab: "",
      chooseProductId: "",
      systemType: "0",
      adult_kid_type: "1",
      tabName: ""
    };
  },
  watch: {
    productTab: {
      handler(id) {
        if (id) {
          let veneer = [];
          // let repair = [];
          const _list = this.productList.filter(item => {
            if (item.id === id) {
              this.systemType = item.systemType;
              this.tabName = item.name;
              if (["4", "5", "6"].includes(item.systemType)) {
                veneer = item.productList;
              }
              // if (item.systemType === "5") {
              //   repair = item.productList;
              // }
            }
            return item.id === id;
          })[0].productList;
          const adult = [];
          const kid = [];
          const other = [];
          const remain = [];
          _list.forEach(v => {
            if (["A", "I"].includes(v.cureType)) {
              adult.push(v);
            }
            if (["B", "C"].includes(v.cureType)) {
              kid.push(v);
            }
            if (!["A", "B", "C", "I"].includes(v.cureType)) {
              other.push(v);
            }
            if (v.cureType === "D") {
              remain.push(v);
            }
          });

          this.productDict = {
            adult,
            kid,
            other,
            remain,
            veneer
            // repair
          };
        }
      },
      deep: true
    }
  },
  methods: {
    changeAdultType(key) {
      this.adult_kid_type = key;
      const parentTop = this.$refs.rootRef.getBoundingClientRect().top;
      this.$nextTick(() => {
        const adultTop = this.$refs.adultRef.getBoundingClientRect().top;
        const kidTop = this.$refs.kidRef.getBoundingClientRect().top;
        if (key === "0") {
          window.scrollTo({
            top: kidTop - parentTop - 20,
            behavior: "smooth"
          });
        } else {
          window.scrollTo({
            top: adultTop - parentTop - 20,
            behavior: "smooth"
          });
        }
      });
    },
    // modifyDetail(_productId) {
    //   if (_productId){
    //     if (_productId!==this.details.productId){
    //       notifyMsg(this, "warning", '产品变动请先填写基本信息', 2500);
    //       return true
    //     }
    //   }else {
    //     return f
    //   }
    // },

    async saveData(key) {
      if (!this.chooseProductId) {
        notifyMsg(this, "warning", "请选择产品", 2500);
        return Promise.reject(this.$emit("saveError"));
      } else {
        if (this.details?.productId) {
          if (key !== 0) {
            if (this.chooseProductId !== this.details.productId) {
              notifyMsg(this, "warning", "产品变动请先填写基本信息", 2500);
              return Promise.reject(this.$emit("saveError"));
            }
          }
        }
        let route = this.$route;
        const createUserForm =
          JSON.parse(sessionStorage.getItem("createUserForm")) || "";
        sessionStorage.setItem(
          "createUserForm",
          JSON.stringify({
            ...createUserForm,
            productId: this.chooseProductId,
            helpFlag: "0"
          })
        );
        let newQuery = { ...route.query, productId: this.chooseProductId };
        this.$router.replace({
          name: route.name,
          query: { ...newQuery }
        });
      }
    },
    chooseProduct(id) {
      this.chooseProductId = id;
    },
    changeProductTab(id) {
      this.productTab = id;
      this.chooseProductId = null;
    },
    async getProductList() {
      const data = await PublicProductTypeListByDoctor();
      this.productTab = data[0].id;
      let _query = this.$route.query;
      const adult = [];
      const kid = [];
      const other = [];
      const remain = [];
      let veneer = [];
      this.systemType = data[0].systemType;
      this.tabName = data[0].name;
      data.forEach(item => {
        const isQueryProduct = item.productList.some(
          v => v.id === _query.productId
        );
        const systemType = item.systemType === "1";
        if (isQueryProduct) {
          this.tabName = item.name;
          this.systemType = item.systemType;
          this.productTab = item.id;
          this.chooseProductId = _query.productId;
        }

        item.productList.forEach(v => {
          if (systemType) {
            if (["A", "I"].includes(v.cureType)) {
              adult.push(v);
            } else if (["B", "C"].includes(v.cureType)) {
              kid.push(v);
            } else if (!["A", "B", "C", "I"].includes(v.cureType)) {
              other.push(v);
            }
          }
          if (v.cureType === "D") {
            remain.push(v);
          }

          if (isQueryProduct) {
            veneer = item.productList;
          }
        });
      });
      this.productDict = {
        adult,
        kid,
        other,
        remain,
        veneer
      };
      this.productList = data;
    }
  },
  created() {
    this.getProductList();
  }
};
</script>

<template>
  <div ref="rootRef" class="create_user">
    <div class="product_title_box">产品系列</div>
    <div class="product_type_box">
      <div class="type_box_left">
        <div
          v-for="item in productList"
          :key="item.id"
          :class="
            `type_left_item ${item.id === productTab ? 'type_active' : ''}`
          "
          @click="changeProductTab(item.id)"
        >
          {{ item.name }}
          <div :class="item.id === productTab ? 'type_active_tab' : ''" />
        </div>
      </div>
      <div
        v-if="
          systemType === '1' &&
            (productDict['adult'].length || productDict['kid'].length)
        "
        class="type_box_right"
      >
        <div
          v-if="productDict['adult'].length"
          :class="adult_kid_type === '1' ? 'product_type_active' : ''"
          class="product_type_adult "
          @click="changeAdultType('1')"
        >
          <a><i class="icon_adult" /><span>成人</span></a>
        </div>
        <div
          v-if="productDict['kid'].length"
          :class="adult_kid_type === '0' ? 'product_type_active' : ''"
          class="product_type_kid"
          @click="changeAdultType('0')"
        >
          <a><i class="icon_kid" /><span>儿童</span></a>
        </div>
      </div>
    </div>
    <div>
      <div v-if="systemType === '1'">
        <div
          v-if="productDict['adult'].length"
          id="adult"
          ref="adultRef"
          class="product_title"
        >
          矫治器-成人
        </div>
        <div v-if="productDict['adult'].length" class="product_flex_box">
          <div
            v-for="item in productDict['adult']"
            :key="item.id"
            class="product_item_box"
            @click="chooseProduct(item.id)"
          >
            <div class="product_img">
              <img :src="`${$PicPrefix}${item.picture}`" alt="" />
              <el-popover
                placement="top-start"
                title="以下机构未签约该产品不可使用"
                trigger="hover"
                width="300"
              >
                <li
                  v-for="cItem in item.unSignedClinicList"
                  class="txt-ellipsis"
                >
                  {{ cItem.name }}
                </li>
                <div
                  v-if="item.unSignedClinicList"
                  slot="reference"
                  class="el-icon-warning-outline unSing_clinic"
                ></div>
              </el-popover>

              <div
                :class="item.id === chooseProductId ? 'product-choose' : ''"
              ></div>
            </div>
            <div class="product_name">
              <p>{{ item.name }}</p>
              <div>
                <ToggleText :key="item.id" :text="item.description" />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="productDict['kid'].length"
          id="kid"
          ref="kidRef"
          class="product_title"
        >
          矫治器-儿童
        </div>
        <div v-if="productDict['kid'].length" class="product_flex_box">
          <div
            v-for="item in productDict['kid']"
            :key="item.id"
            class="product_item_box"
            @click="chooseProduct(item.id)"
          >
            <div class="product_img">
              <img :src="`${$PicPrefix}${item.picture}`" alt="" />

              <div
                :class="item.id === chooseProductId ? 'product-choose' : ''"
              ></div>
            </div>
            <div class="product_name">
              <p>{{ item.name }}</p>
              <div>
                <ToggleText :key="item.id" :text="item.description" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="productDict['other'].length" class="product_title">
          矫治器-其他
        </div>
        <div v-if="productDict['other'].length" class="product_flex_box">
          <div
            v-for="item in productDict['other']"
            :key="item.id"
            class="product_item_box"
            @click="chooseProduct(item.id)"
          >
            <div class="product_img">
              <img :src="`${$PicPrefix}${item.picture}`" alt="" />

              <div
                :class="item.id === chooseProductId ? 'product-choose' : ''"
              ></div>
            </div>
            <div class="product_name">
              <p>{{ item.name }}</p>
              <div>
                <ToggleText :key="item.id" :text="item.description" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="['2', '3'].includes(systemType)">
        <div class="product_title">{{ tabName }}</div>
        <div class="product_flex_box">
          <div
            v-for="item in productDict['remain']"
            class="product_item_box"
            @click="chooseProduct(item.id)"
          >
            <div class="product_img">
              <img :src="`${$PicPrefix}${item.picture}`" alt="" />

              <div
                :class="item.id === chooseProductId ? 'product-choose' : ''"
              ></div>
            </div>
            <div class="product_name">
              <p>{{ item.name }}</p>
              <div>
                <ToggleText :key="item.id" :text="item.description" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="['4', '5', '6'].includes(systemType)">
        <div class="product_title">{{ tabName }}</div>
        <div class="product_flex_box">
          <div
            v-for="item in productDict['veneer']"
            class="product_item_box"
            @click="chooseProduct(item.id)"
          >
            <div class="product_img">
              <img :alt="item.name" :src="`${$PicPrefix}${item.picture}`" />
              <div
                :class="item.id === chooseProductId ? 'product-choose' : ''"
              ></div>
            </div>
            <div class="product_name">
              <p>{{ item.name }}</p>
              <div>
                <ToggleText :key="item.id" :text="item.description" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.create_user {
  background-color: #fff;
  border-radius: 0.06rem;
  padding: 0.16rem 0.3rem 2rem;

  .product_title_box {
    width: 704px;
    height: 28px;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .product_type_box {
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;

    .type_box_left {
      display: flex;
      height: 26px;

      .type_left_item {
        font-size: 18px;
        color: #333333;
        line-height: 26px;
        text-align: center;
        margin-right: 40px;
        cursor: pointer;
      }

      .type_active {
        font-weight: bold;
        color: #fcc80e;

        .type_active_tab {
          margin-top: 10px;
          height: 4px;
          background: #fcc80e;
          border-radius: 100px 100px 100px 100px;
        }
      }
    }

    .type_box_right {
      width: 192px;
      height: 36px;
      background: #e5e5e5;
      border-radius: 100px;
      display: flex;
      text-align: center;
      justify-items: center;
      align-items: center;
      font-size: 18px;
      color: #666666;

      .product_type_active {
        background-color: #fcc80e;
        color: #333;
        transition: 0.3s all;

        a {
          color: #333 !important;
        }
      }

      .product_type_adult,
      .product_type_kid {
        cursor: pointer;
        border-radius: 100px;
        width: 50%;
        height: 100%;
        line-height: 36px;
        transition: 0.3s all;

        a {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: center;
          justify-items: center;

          span {
            line-height: 36px;
          }
        }
      }
    }
  }

  .product_title {
    //width: 148px;
    height: 26px;
    border-left: 4px solid #fcc80e;
    line-height: 26px;
    padding-left: 10px;
    border-radius: 4px;
    color: #333333;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 16px;
    margin-top: 48px;
  }

  .product_item_box:hover {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
    transition: 0.2s all;
  }

  .product_item_box {
    width: 356px;
    min-height: 298px;
    border-radius: 8px;
    padding: 8px;
    transition: 0.2s all;
    cursor: pointer;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    //box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
    .product_img {
      //width: 292px;
      width: 340px;
      height: 204px;
      border-radius: 6px;
      position: relative;
      margin-bottom: 12px;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      overflow: hidden;

      img {
        place-items: center;
        //display: grid;
        height: 100%;
        //width: 100%;
        display: block;
        margin: auto;
        transition: transform 0.3s ease; /* 平滑放大效果 */
        overflow: hidden;
      }

      .unSing_clinic {
        font-size: 20px;
        position: absolute;
        right: 10px;
        top: 10px;
        color: #fff;
      }

      .product-choose {
        width: 32px;
        height: 32px;
        background-image: url(../../../common/imgs/create-product/product_choose.png);
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        bottom: 0;
        transition: 0.2s;
      }
    }

    .product_img:hover {
      > img {
        transform: scale(1.05); /* 放大到110% */
        transition: transform 0.3s ease; /* 平滑放大效果 */
      }
    }

    .product_name {
      p:first-child {
        color: #333333;
        font-size: 16px !important;
        font-weight: bold;
        line-height: 22px;
        margin-bottom: 5px;
      }

      div:last-child {
        font-size: 14px !important;
        color: #666666 !important;
        line-height: 20px !important;
      }
    }
  }
}

.product_flex_box {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 创建三列，每列占据等分空间 */
  box-sizing: border-box;
  grid-gap: 30px;
}

.icon_adult {
  width: 24px;
  height: 24px;
  background-image: url("../../../common/imgs/create-product/icon_adult.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  margin-bottom: 1px;
}

.icon_kid {
  width: 24px;
  height: 24px;
  background-image: url("../../../common/imgs/create-product/icon_kid.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  margin-bottom: 1px;
}
</style>
