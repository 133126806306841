<template>
  <div class="create_user">
    <div class="user_head">
      <div class="user_head_left">
        <span class="vertical_line"></span>{{ $t("cases.createUser.jbxx") }}
      </div>
      <p class="user_head_p">
        <span class="user_head_sp"
          >{{ $t("casesDetail.casesChildren.restartCases.blh") }}:</span
        >
        <span class="case_number">{{ caseNumber || "-" }}</span>
      </p>
    </div>
    <div class="dia_problem" v-if="problemList.length > 0">
      <h5>{{ $t("cases.createImage.czrxwt") }}</h5>
      <div class="pro_box">
        <span v-for="(item, index) in problemList" :key="index">
          {{ item.groupName }} : {{ item.content }}
        </span>
      </div>
    </div>
    <ul class="form_ul">
      <li class="f_single">
        <div
          :style="
            language === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : ''
          "
          class="f_single_left"
        >
          <span class="d_require">●</span> {{ $t("cases.createUser.hzmc") }}：
        </div>
        <div class="f_single_right">
          <input
            type="text"
            :placeholder="$t('cases.createUser.qsr')"
            v-model="userForm.realName"
          />
        </div>
      </li>
      <li class="f_single">
        <div
          :style="
            language === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : ''
          "
          class="f_single_left"
        >
          <span class="d_require">●</span> {{ $t("cases.createUser.xb") }}：
        </div>
        <div class="f_single_right" style="display: flex; align-items: center">
          <el-radio v-model="userForm.sex" label="1">{{
            $t("cases.createUser.n")
          }}</el-radio>
          <el-radio v-model="userForm.sex" label="2">{{
            $t("cases.createUser.v")
          }}</el-radio>
        </div>
      </li>
      <li class="f_single">
        <div
          :style="
            language === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : ''
          "
          class="f_single_left"
        >
          <span class="d_require">●</span> {{ $t("cases.createUser.csrq") }}：
        </div>
        <div class="f_single_right flex-y-c">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="birthday"
            :default-value="timeDefaultShow"
            :picker-options="pickerOptions"
            type="date"
            :placeholder="$t('cases.createUser.xzrq')"
            style="width: 2.74rem"
            @change="datePickerValue"
          >
          </el-date-picker>
          <!-- <div v-if="!userForm.birthday" class="ml20 fz12 main_theme_color_red">
            {{ birthdayHintText }}
          </div> -->
        </div>
      </li>
      <li
        :style="userForm.receiveType === '2' ? 'margin-top: -0.1rem' : ''"
        class="f_single"
      >
        <div
          :style="
            language === 'en_US'
              ? 'width: 2.1rem; text-align: left;padding-left: 0.15rem'
              : ''
          "
          class="f_single_left"
        >
          {{ $t("birdie.lxdh") }}：
        </div>
        <div class="f_single_right">
          <input
            :placeholder="$t('cases.createUser.qsrlxdh')"
            v-model="userForm.mobile"
          />
        </div>
      </li>
      <li class="f_single">
        <div
          :style="
            language === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : ''
          "
          class="f_single_left"
        >
          <span class="d_require">●</span> {{ $t("cases.cases.cp") }}：
        </div>
        <el-select
          :disabled="isDisabledProduct"
          clearable
          @change="selectProduct"
          style="width: 2.74rem"
          v-model="userForm.productId"
          :placeholder="$t('common.message.qxz')"
        >
          <el-option
            v-for="(item, index) in productList"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </li>
      <li class="f_single" v-if="bindProductList.length">
        <div
          :style="
            language === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : ''
          "
          class="f_single_left"
        >
          <span class="d_require">●</span>{{ $t("cases.createUser.tcbdcp") }} ：
        </div>
        <el-select
          :disabled="isDisabledBindProduct"
          v-model="bindProductId"
          clearable
          @change="selectBindProduct"
          style="width: 2.74rem"
        >
          <el-option
            v-for="(item, index) in bindProductList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </li>
      <div class="user_head" style="margin-bottom: 30px;">
        <div class="user_head_left">
          <span class="vertical_line"></span>医疗机构
        </div>
      </div>
      <li class="f_single" style="position: relative">
        <div
          :style="
            language === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : ''
          "
          class="f_single_left"
        >
          <span class="d_require">●</span> 医疗机构：
        </div>
        <el-select
          clearable
          style="width: 2.74rem"
          v-model="userForm.clinicId"
          :placeholder="$t('common.message.qxz')"
          @change="selectedClinic"
          :disabled="disableClinicSelect"
        >
          <!--          :disabled="disabledCLinic(item)"-->

          <el-option
            style="width: 500px !important;"
            :disabled="disabledCLinic(item)"
            v-for="(item, index) in clinicList"
            :key="index"
            :label="item.name"
            :value="item.id"
            :style="disabledCLinic(item) ? { color: '#999 !important' } : {}"
          >
            <span class="txt-ellipsis" :title="item.name">{{ item.name }}</span>
            <span
              v-if="disabledCLinic(item)"
              style="float: right; color: #8492a6; font-size: 13px"
              >不可用</span
            >
          </el-option> </el-select
        ><br />
        <p class="disabled_txt">
          {{ clinicDisableText }}
        </p>
      </li>

      <li class="f_single">
        <div
          :style="
            language === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : ''
          "
          class="f_single_left"
        >
          <span class="d_require">●</span> {{ $t("cases.createUser.hzjsyt") }}：
        </div>
        <div class="f_single_right" style="display: flex; align-items: center">
          <el-radio v-model="userForm.receiveType" label="1">{{
            $t("cases.createUser.zssh")
          }}</el-radio>
          <el-radio v-model="userForm.receiveType" label="3">
            {{ $t("yssh.yssh") }}
          </el-radio>
          <el-radio v-model="userForm.receiveType" label="2">{{
            $t("cases.createUser.trsh")
          }}</el-radio>
        </div>
      </li>
      <li v-show="userForm.receiveType === '1'" class="f_single">
        <div
          :style="
            language === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : ''
          "
          class="f_single_left"
        >
          <span class="d_require">●</span> 收货地址：
        </div>
        <div class="f_single_right">
          <el-select v-model="clinicAddressId" style="width: 2.74rem">
            <el-option
              v-for="(item, index) in clinicAddressList"
              :key="index"
              :label="item.clinicAddress"
              :value="item.id"
              style="max-width: 9rem !important;"
              :title="item.clinicAddress"
            >
            </el-option>
          </el-select>
        </div>
      </li>
      <li v-show="userForm.receiveType === '3'" class="f_single">
        <div
          :style="
            language === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : ''
          "
          class="f_single_left"
        >
          <span class="d_require">●</span> {{ $t("yssh.ysshdz") }}：
        </div>
        <div class="f_single_right" style="display: flex; align-items: center">
          <div
            :class="
              doctorAddressId === '' ? 'doctor-select' : 'doctor-select-active'
            "
            :title="
              doctorAddressId === '' ? $t('yssh.djdzxz') : renderAddressText()
            "
            @click="showDoctorAddressDia"
          >
            {{
              doctorAddressId === "" ? $t("yssh.djdzxz") : renderAddressText()
            }}
          </div>
          <span class="select-text" @click="showDoctorAddressDia">{{
            $t("yssh.dzxz")
          }}</span>
        </div>
      </li>
      <li v-show="userForm.receiveType === '2'" class="f_single">
        <div
          :style="
            language === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : ''
          "
          class="f_single_left"
        >
          {{ $t("cases.createUser.znjx") }}：
        </div>
        <div class="iup_box">
          <div class="f_single_right">
            <textarea
              class="message-add"
              rows="3"
              v-model="address"
              :placeholder="$t('cases.createUser.znjxplaceholder')"
            />
            <a class="message-parse" @click="getMessage">{{
              $t("cases.createUser.jx")
            }}</a>
            <span class="message-reset" @click="resetMessage">{{
              $t("cases.createUser.qk")
            }}</span>
          </div>
          <span class="tip">{{ $t("cases.createUser.jxhqhsxx") }}</span>
        </div>
      </li>
      <li v-show="userForm.receiveType === '2'" class="f_single">
        <div
          :style="
            language === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : ''
          "
          class="f_single_left"
        >
          <span class="d_require">●</span> {{ $t("cases.createUser.sjrxm") }}：
        </div>
        <div class="f_single_right">
          <input
            :placeholder="$t('cases.createUser.qtxsjrxm')"
            v-model="userForm.receiver"
          />
        </div>
      </li>

      <li v-show="userForm.receiveType === '2'" class="f_single">
        <div
          :style="
            language === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : ''
          "
          class="f_single_left"
        >
          <span class="d_require">●</span>
          {{ $t("cases.createUser.sjrsjhm") }}：
        </div>
        <div class="f_single_right" style="display: flex">
          <el-select v-model="contactWay" @change="changeContactWay">
            <el-option
              v-for="item in contactWayList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <input
            v-if="contactWay == 1"
            type="number"
            :placeholder="$t('cases.createUser.qsrsjrsjhm')"
            style="margin-left: 0.2rem; width: 3.5rem;"
            v-model="userForm.receiverMobile"
          />
          <div
            v-if="contactWay == 2"
            style="margin-left: 0.2rem; display: flex; align-items: center"
          >
            <input
              type="number"
              style="width: 1rem; width: 1.5rem;"
              v-model="userForm.phoneArea"
              :placeholder="$t('birdie.qh')"
            />
            <div
              style="width: 0.3rem;height: 1px;background:#bbbbbb;margin: 0.01rem;"
            />
            <input
              v-model="userForm.receiverMobile"
              type="number"
              :placeholder="$t('birdie.dhhm')"
            />
          </div>
        </div>
      </li>

      <li
        v-show="userForm.receiveType === '2'"
        class="f_single"
        style="align-items: self-start"
      >
        <div
          style="padding-top: 0.1rem"
          :style="
            language === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : ''
          "
          class="f_single_left"
        >
          <span v-show="userForm.receiveType === '2'" class="d_require">●</span>
          {{ $t("cases.createUser.dz") }}：
        </div>
        <div class="f_single_right">
          <div class="select_box">
            <el-select
              :placeholder="$t('cases.createUser.sf')"
              v-model="userForm.province"
              @change="changeProvince"
            >
              <el-option
                :value="item.id"
                v-for="(item, index) in cityData"
                :key="index"
                :label="item.name"
              ></el-option>
            </el-select>
            <el-select
              :placeholder="$t('cases.createUser.cs')"
              v-model="userForm.city"
              style="margin: 0 0.2rem"
              @change="changeCity"
            >
              <el-option
                v-for="(item, index) in theCityList"
                :value="item.id"
                :label="item.name"
                :key="index"
              ></el-option>
            </el-select>
            <el-select
              :placeholder="$t('cases.createUser.qx')"
              v-model="userForm.area"
            >
              <el-option
                v-for="(item, index) in countyList"
                :value="item.id"
                :label="item.name"
                :key="index"
              ></el-option>
            </el-select>
          </div>
          <div class="iup_box flex-y-c">
            <div
              :style="
                language === 'en_US'
                  ? 'width: 1.8rem; margin-left: -2.4rem;margin-right: 0.6rem'
                  : 'margin-left: -1.5rem;'
              "
              class="f_single_left"
            >
              <span v-show="userForm.receiveType === '2'" class="d_require"
                >●</span
              >
              {{ $t("cases.createUser.xxdz") }}：
            </div>
            <input
              type="text"
              :placeholder="$t('cases.createUser.xxdz')"
              v-model="userForm.address"
            />
          </div>
        </div>
      </li>
    </ul>
    <div v-if="dialogVisible" class="user_head">
      <div class="user_head_left">
        <span class="vertical_line"></span>iRDT协助
        <el-popover placement="top-start" trigger="click">
          <span
            :style="{
              color: '#fcc80e',
              // textDecoration: 'underline',
              cursor: 'pointer'
            }"
            @click="jumpClinicalPreferences"
            >什么是iRDT协助</span
          >
          <i style="cursor: pointer;" slot="reference" class="icon_question" />
        </el-popover>
      </div>
    </div>
    <ul v-if="dialogVisible" class="form_ul" style="margin-bottom: 0;">
      <li class="f_single">
        <div
          :style="
            language === 'en_US'
              ? 'width: 2.3rem; text-align: left;margin-right:0'
              : ''
          "
          class="f_single_left"
        >
          是否iRDT协助：
        </div>
        <div class="f_single_right" style="display: flex; align-items: center">
          <div
            :class="`rdt_btn ${helpFlag === '1' ? 'rdt_btn_active' : ''}`"
            @click="changeRdt('1')"
          >
            需要
          </div>
          <div
            :class="`rdt_btn ${helpFlag === '0' ? 'rdt_btn_active' : ''}`"
            @click="changeRdt('0')"
          >
            不需要
          </div>
        </div>
      </li>
    </ul>
    <full-loading v-show="isLoading" />
    <el-dialog
      :title="$t('casesDetail.clinicalPreferences.RTD')"
      :before-close="closeDialog"
      :close-on-press-escape="false"
      :show-close="false"
      :close-on-click-modal="false"
      width="4.58rem"
    >
      <p class="rdt_text">
        <span>{{ $t("casesDetail.clinicalPreferences.sfxyRTD") }}</span>
        <span
          :style="{
            color: '#fcc80e',
            textDecoration: 'underline',
            cursor: 'pointer'
          }"
          @click="jumpClinicalPreferences"
        >
          {{ $t("casesDetail.clinicalPreferences.smsRTD") }}
        </span>
      </p>
      <div slot="footer" class="dialog-footer">
        <div
          :class="{ no_need_btn_en: language === 'en_US' }"
          @click="needRDT(0)"
          class="no_need_btn flex-x-y-c curp fz14 main_theme_color_333"
        >
          {{ $t("casesDetail.clinicalPreferences.bxy") }}
        </div>
        <div
          :class="{ no_need_btn_en: language === 'en_US' }"
          @click="needRDT(1)"
          class="need_btn flex-x-y-c curp fz14 main_theme_color_333"
        >
          {{ $t("casesDetail.clinicalPreferences.xy") }}
        </div>
      </div>
    </el-dialog>
    <el-dialog
      class="address-dia"
      width="588px"
      :visible="showDoctorAddress"
      :show-close="false"
      @close="showDoctorAddress = false"
    >
      <div class="dia-header">
        <div class="title">
          {{ $t("yssh.dzxz") }}
          <div class="add-address" @click="addDoctorAddress">
            <img src="../../../common/imgs/personal/position.png" alt="" />
            {{ $t("yssh.tjdz") }}
          </div>
        </div>
        <i
          @click.stop="showDoctorAddress = false"
          class="el-icon-close close-dia"
        />

        <!--        <img-->
        <!--          @click.stop="showDoctorAddress = false"-->
        <!--          class="close-dia"-->
        <!--          src="../../../common/imgs/expressage_birdie/dia_close.png"-->
        <!--          alt=""-->
        <!--        />-->
      </div>
      <div class="dia-content">
        <div v-if="!addressList.length" class="no-data">
          <img src="../../../common/imgs/personal/no-address.png" alt="" />
          <span>{{ $t("yssh.nzwtjrhshdz") }}</span>
        </div>
        <div
          class="address-item"
          v-for="(item, index) in addressList"
          :key="index"
        >
          <AddressCard
            :isCreated="true"
            :showDefault="true"
            :info="item"
            @confirm="choosenCurrent"
            @cancel="editCurrent"
          />
        </div>
      </div>
      <div class="dia-footer"></div>
    </el-dialog>
    <AddressEdit
      :visible="visible"
      @cancel="visible = false"
      @confirm="confirmAddress"
      :isEdit="isEdit"
      :editId="editId"
    />
  </div>
</template>

<script>
import FullLoading from "components/full-loading/full-loading";
import $ from "jquery";
import { getUserId, formatDate, mobileValid, notifyMsg } from "common/js/util";
import { getCityList } from "common/api/public";
import AddressCard from "../../../components/address-card/index.vue";
import AddressEdit from "../../../components/address-edit/index.vue";
import {
  clinicDoctorList,
  createCases,
  casesDetail,
  editCases,
  cureProblemList,
  optionalListByProduct,
  PublicSendAddressList,
  clinicAddress
} from "common/api/cases";
import { mapState } from "vuex";
import {
  get,
  map,
  each,
  find,
  filter,
  pickBy,
  flattenDeep,
  extend
} from "lodash";
import moment from "moment";
import CDate from "common/js/CDate";
import { getProductTypeListByDoctor } from "common/api/public";
import { changeRouteQuery } from "../../../common/js/Fn";
import { mapGetters, mapActions } from "vuex";
import AddressParse from "address-parse";

export default {
  props: {
    productClear: {
      required: false
    },
    tabIndex: {
      required: false
    }
  },
  data() {
    const _this = this;
    return {
      birthday: "",
      clinicDisableText: "",
      disabledDate: {
        customPredictor: function(date) {
          if (date > Date.now() - 1000 * 60 * 60 * 24 * 30 * 12 * 5) {
            return true;
          }
        }
      },
      contactWayList: [
        { idx: 0, key: "1", value: this.$t("cases.createUser.sjhm") },
        { idx: 1, key: "2", value: this.$t("birdie.gddh") }
      ],
      editId: "",
      isEdit: false,
      visible: false,
      addressList: [],
      showDoctorAddress: false,
      doctorAddressId: "",
      doctorAddressInfo: {},
      dialogVisible: false,
      isLoading: false,
      address: "",
      helpFlag: "",
      bindProductId: "",
      userForm: {
        helpFlag: "",
        sex: "", //性别
        birthday: "",
        realName: "",
        mobile: "",
        receiverMobile: "",
        province: "", //省
        city: "", //市
        area: "", //区
        address: "", //地址
        productId: "", //产品
        bindProductId: "",
        clinicId: "", //诊所
        receiveType: "1",
        phoneArea: "",
        receiver: "",
        doctorId: getUserId()
      }, // 表单form
      contactWay: "1",
      caseNumber: "", // 患者号
      clinicList: [], // 诊所列表
      clinicAddressList: [],
      clinicAddressId: "",
      productList: [], // 产品列表
      cityData: [],
      theCityList: [],
      countyList: [],
      clinicListDom: null,
      productListDom: null,
      clickObj: {
        clinicIndex: 0,
        productIndex: 0
      },
      caseId: "",
      clinicNumber: "",
      backClinicId: "",
      clinicMsgObj: {},
      productMsgObj: {},
      CITY: [], // 总省份数据
      lang: localStorage.getItem("user_lang") || "zh_CN",
      pickerOptions: {
        disabledDate(time) {
          // let productType = _this.productType;
          // let oneDay = 86400000;
          // if (["F"].includes(productType) || _this.details["type"] === "1") {
          //   return time.getTime() > Date.now();
          // }
          // if (["A", "I", "D", "E"].includes(productType)) {
          //   return (
          //     time.getTime() >
          //     Date.now() - 1000 * 60 * 60 * 24 * 365 * 5 - oneDay
          //   );
          // }
          // if (["B", "G", "H"].includes(productType)) {
          //   let _now = Date.now(),
          //     sixteen = _now - 1000 * 60 * 60 * 24 * 365 * 13 - oneDay,
          //     three = _now - 1000 * 60 * 60 * 24 * 365 * 3 - oneDay;
          //   return sixteen > time.getTime() || time.getTime() > three;
          // }
          // if (["C"].includes(productType)) {
          //   let _now = Date.now(),
          //     sixteen = _now - 1000 * 60 * 60 * 24 * 365 * 6 - oneDay,
          //     three = _now - 1000 * 60 * 60 * 24 * 365 * 3 - oneDay;
          //   return sixteen > time.getTime() || time.getTime() > three;
          // }
          return time.getTime() > Date.now();
        }
      },
      details: "",
      cannotSelectList: [],

      problemList: [],
      sumProblemList: [],
      diagnosisProblem: {},

      isResponse: 0,
      productType: "",
      bindProductNumber: ""
    };
  },
  watch: {
    cityList: {
      handler(cityData) {
        if (cityData?.length) {
          this.CITY = cityData;
          this.cityData = cityData;
          const { province, city } = this.userForm;
          const list = cityData.filter(item => item.id === province);
          if (list.length) {
            this.theCityList = cityData.filter(
              item => item.id === province
            )[0].sub;
            this.countyList = this.theCityList.filter(
              item => item.id === city
            )[0].sub;
          }
        }
      },
      deep: true,
      immediate: true
    },
    // clinicMsgObj: {
    //   handler(val) {
    //     this.$emit("getClinicMsg", val?.[this.userForm.clinicId]);
    //   },
    //   deep: true,
    //   immediate: true
    // },
    // productMsgObj: {
    //   handler(val) {
    //     this.$emit("getProductMsg", val?.[this.userForm.productId]);
    //   },
    //   deep: true,
    //   immediate: true
    // },
    "userForm.productId": {
      handler: function(val) {
        let route = this.$route;
        // this.userForm.bindProductId = "";
        // this.bindProductId = "";
        this.$router.replace({
          name: route.name,
          query: extend({}, route.query, { productId: val })
        });
      }
    },
    "userForm.bindProductId": {
      handler: function(val) {
        if (val === "0") {
          this.userForm.bindProductId = "";
          this.bindProductId = "";
        }
      },
      deep: true,
      immediate: true
    },
    "userForm.receiveType": {
      handler: function(val) {
        if (val == "1") {
          this.userForm.receiver = "";
          this.userForm.phoneArea = this.userForm.receiverMobile = "";
          this.userForm.contactWay = "1";
          this.userForm.province = this.userForm.city = this.userForm.area = this.userForm.address =
            "";
        } else if (val === "2") {
          this.doctorAddressId = "";
          this.doctorAddressInfo = {};
          if (this.details.receiveType === "2") {
            this.userForm = {
              ...this.userForm,
              province: get(this.details.otherReceiveAddress, "province") || "",
              city: get(this.details.otherReceiveAddress, "city") || "",
              area: get(this.details.otherReceiveAddress, "area") || "",
              address: get(this.details.otherReceiveAddress, "address") || "",
              receiverMobile:
                get(this.details.otherReceiveAddress, "phone") || "",
              phoneArea:
                get(this.details.otherReceiveAddress, "phoneArea") || "",
              receiver: get(this.details.otherReceiveAddress, "receiveUser"),
              contactWay:
                get(this.details.otherReceiveAddress, "contactWay") || "1"
            };
          } else {
            this.userForm.receiver = "";
            this.userForm.phoneArea = this.userForm.receiverMobile = "";
            this.userForm.contactWay = "1";
            this.userForm.province = this.userForm.city = this.userForm.area = this.userForm.address =
              "";
          }
        } else if (val === "3") {
          if (this.details.receiveType === "3") {
            this.doctorAddressId = this.details.receiveAddressId;
            this.doctorAddressInfo = this.details.otherReceiveAddress;
          }
          if (!this.doctorAddressId) {
            this.addressList.forEach(item => {
              if (item.defaultFlag === "1") {
                this.choosenCurrent(item);
              }
            });
          }
          this.userForm.receiver = "";
          this.userForm.phoneArea = this.userForm.receiverMobile = "";
          this.userForm.contactWay = "1";
          this.userForm.province = this.userForm.city = this.userForm.area = this.userForm.address =
            "";
        }
      }
    },
    tabIndex: {
      handler: function(nval) {
        if (nval == 0) {
          this.productList = [];
          this.init(this.$route.query.caseId);
        } else {
          return;
        }
      }
    },
    productClear: {
      handler: function(val) {
        if (val) {
          this.productList = [];
          this.getProductList();
          if (!this.details) {
            this.userForm.productId = "";
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      language: state => state.language,
      cityList: state => state.cityList
    }),

    ...mapGetters({
      commonAddProductList: "getCommonAddProductList" //追加产品列表
    }),
    disableClinicSelect() {
      if (this.details) {
        // if (this.details["type"] === "2") {
        //   this.clinicDisableText = "产品追加，不支持切换";
        //   return true;
        // }
        if (
          this.details.curePlanInfo &&
          this.details?.curePlanInfo?.["reopenNumber"] !== 0
        ) {
          this.clinicDisableText = "病例重启，不支持切换";
          return true;
        }
      }
    },
    disabledCLinic() {
      return function(item) {
        if (this.unSignedClinicListId.includes(item.id)) {
          return true;
        }
        // if (
        //   ["1", "2"].includes(this.details["type"]) ||
        //   this.details["reopenFlag"] === "1" ||
        //   this.sumProblemList.length ||
        //   this.isDisabledProduct ||
        //   this.details["nextStatus"] === "to_resubmit"
        // ) {
        //   if (
        //     "clinicId" in this.details &&
        //     this.details["clinicId"] !== item.id &&
        //     this.details["status"] !== "precuring"
        //   ) {
        //     return true;
        //   }
        // }
      };
    },
    unSignedClinicListId() {
      const _list = flattenDeep(
        map(this.productList, item => {
          if (item.id === this.$route.query.productId) {
            return map(get(item, "unSignedClinicList"), it => it.id);
          }
          return [];
        })
      );
      if (_list.includes(this.userForm.clinicId)) {
        if (!this.clinicDisableText){
          this.userForm.clinicId = "";
          this.doctorAddressId = "";
          this.clinicAddressId = "";
        }
      }
      return _list;
    },
    bindProductList() {
      // this.bindProductId = "";
      const current = this.productList.filter(
        it => it.id === this.$route.query.productId
      );
      // if (current[0]?.bindProductList) {
      //   const hasDefault = find(
      //     current[0].bindProductList,
      //     it => it.defaultFlag === "1"
      //   );
      //   // if (this.details['bindProductId'] !== '0' && this.details['bindProductId'] === hasDefault['id']) {
      //   //   this.userForm.bindProductId = this.details['bindProductId']
      //   // } else {
      //   //   this.userForm.bindProductId = hasDefault.id
      //   // }
      //   if (this.details["productId"] !== this.userForm["productId"]) {
      //     this.productType = hasDefault?.cureType;
      //     this.userForm.bindProductId = hasDefault.id;
      //     this.bindProductId = hasDefault.id;
      //   } else {
      //     if (!Object.keys(this.details).length) {
      //       this.bindProductNumber = hasDefault.number;
      //       this.userForm.bindProductId = hasDefault.id;
      //       this.bindProductId = hasDefault.id;
      //     } else {
      //       this.userForm.bindProductId = this.details["bindProductId"];
      //       this.bindProductId = this.details["bindProductId"];
      //       this.productType = this.details["cureType"];
      //     }
      //   }
      // }
      return current[0]?.bindProductList || [];
    },
    timeDefaultShow() {
      // return {
      //   A: Date.now() - 1000 * 60 * 60 * 24 * 365 * 5,
      //   B: Date.now() - 1000 * 60 * 60 * 24 * 365 * 13,
      //   C: Date.now() - 1000 * 60 * 60 * 24 * 365 * 6,
      //   D: Date.now() - 1000 * 60 * 60 * 24 * 365 * 5,
      //   E: Date.now() - 1000 * 60 * 60 * 24 * 365 * 5,
      //   F: Date.now(),
      //   G: Date.now() - 1000 * 60 * 60 * 24 * 365 * 13,
      //   H: Date.now() - 1000 * 60 * 60 * 24 * 365 * 13,
      //   I: Date.now() - 1000 * 60 * 60 * 24 * 365 * 5,
      // }[this.productType];
      return Date.now();
    },
    birthdayHintText() {
      return {
        A: this.$t("cases.createUser.nlbxdyfive"),
        B: this.$t("cases.createUser.nlthreetothreeteen"),
        C: this.$t("cases.createUser.nlthreetosix"),
        D: this.$t("cases.createUser.nlbxdyfive"),
        E: this.$t("cases.createUser.nlbxdyfive"),
        F: "",
        G: this.$t("cases.createUser.nlthreetothreeteen"),
        H: this.$t("cases.createUser.nlthreetothreeteen"),
        I: this.$t("cases.createUser.nlbxdyfive")
      }[this.productType];
    },
    queryCaseId() {
      return this.$route.query.caseId;
    },
    queryCurePlanId() {
      return this.$route.query.curePlanId;
    },
    isDisabled() {
      return !!this.sumProblemList.length || this.details.reopenFlag === "1";
    },
    isDisabledProduct() {
      if (this.details) {
        if (
          ["5", "6"].includes(this.details["productSystemType"]) &&
          this.details["type"] !== "0"
        ) {
          return true;
        }
        if (this.details["cureType"] === "F") {
          return false;
        }
        let boolean = this.productList.some(
          item => this.details["productId"] === item.id
        );
        if (
          this.details.nextStatus === "to_resubmit" &&
          this.sumProblemList.length &&
          this.problemList.length
        ) {
          if (
            ["5", "6"].includes(this.details["productSystemType"]) &&
            this.details["number"] !== 1
          ) {
            return true;
          }
        }
        if (
          this.details.nextStatus === "to_resubmit" &&
          !!this.sumProblemList.length &&
          !this.problemList.length
        ) {
          return true;
        } else if (this.details.reopenFlag === "1") {
          return !boolean ? false : true;
        }
      }
    },
    isDisabledBindProduct() {
      if (this.details["cureType"] === "F") {
        return false;
      }
      let boolean = this.productList.some(
        item => this.details["productId"] === item.id
      );
      if (
        this.details.nextStatus === "to_resubmit" &&
        !!this.sumProblemList.length &&
        !this.problemList.length
      ) {
        return true;
      } else if (this.details.reopenFlag === "1") {
        return !boolean ? false : true;
      }
    }
  },
  methods: {
    moment,
    ...mapActions({
      getCommonAddProductList: "actionGetCommonAddProductList" //追加产品列表
    }),

    renderAddressText() {
      const {
        receiveUser,
        sender,
        contactWay,
        phoneArea,
        phone,
        provinceName,
        cityName,
        areaName,
        address
      } = this.doctorAddressInfo;
      return `${sender || receiveUser}- ( ${
        contactWay === "2" ? `${phoneArea}-${phone}` : phone
      } ) -${provinceName}-${cityName}-${areaName}-${address}`;
    },
    confirmAddress() {
      this.visible = false;
      this.getSendAddressList();
    },
    choosenCurrent(info) {
      this.doctorAddressInfo = info;
      this.doctorAddressId = info.id;
      this.showDoctorAddress = false;
    },
    addDoctorAddress() {
      this.editId = "";
      this.isEdit = false;
      this.visible = true;
    },
    editCurrent(info) {
      this.editId = info.id;
      this.isEdit = true;
      this.visible = true;
    },
    getClinicList(id) {
      clinicAddress({ refCode: id, refType: "CLINIC" }).then(data => {
        this.clinicAddressList = data.map(v => {
          if (this.details.clinicId || this.userForm.clinicId) {
            if (v.defaultFlag === "1") {
              this.clinicAddressId = v.id;
            }
          }
          const {
            id,
            receiveUser,
            phone,
            provinceName,
            cityName,
            areaName,
            address,
            phoneArea,
            contactWay
          } = v;
          const phoneNum = contactWay === "2" ? `${phoneArea}-${phone}` : phone;
          v.clinicAddress = `${receiveUser}-${phoneNum}-${provinceName}-${cityName}-${areaName}-${address}`;
          return v;
        });
      });
    },
    getSendAddressList() {
      PublicSendAddressList({
        refCode: localStorage.getItem("userId"),
        refType: "DOCTOR"
      }).then(res => {
        this.addressList = res;
        res.forEach(item => {
          if (this.doctorAddressInfo?.id === item.id) {
            this.doctorAddressInfo = item;
          }
        });
      });
    },
    showDoctorAddressDia() {
      this.showDoctorAddress = true;
    },
    changeContactWay(e) {
      this.userForm.phoneArea = "";
      this.userForm.receiverMobile = "";
    },
    selectProduct(id) {
      if (id) {
        if (!this.disableClinicSelect) {
          let obj = find(this.productList, item => item.id === id);
          this.cannotSelectList = map(get(obj, "unSignedClinicList"), item => {
            return item.id;
          });
          this.changeRoute({
            productId: obj.id
          });
          this.productType = get(obj, "cureType");
        }
        this.userForm.productId = id;
        this.clinicAddressList = [];
        this.clinicAddressId = "";
        this.dialogVisible = false;
      } else {
        this.productType = "";
        this.clinicAddressList = [];
        this.clinicAddressId = "";
        this.dialogVisible = false;
      }
      this.userForm.birthday = "";
      this.birthday = "";
      this.userForm.helpFlag = "";
      this.userForm.clinicId = "";
      this.userForm.bindProductId = "";
      this.bindProductId = "";
      this.$emit("getProductMsg", this.productMsgObj[id]);
    },
    selectBindProduct(id) {
      this.userForm.birthday = "";
      this.birthday = "";
      this.userForm.bindProductId = id;
      this.bindProductId = id;
      this.selectClinicId(this.userForm.clinicId);
      if (id) {
        const product = this.bindProductList.find(item => item.id === id);
        if (product) {
          this.$set(this, "bindProductNumber", product.number);
        }
      } else {
        this.bindProductNumber = ""; // 或者设置为默认值
      }
    },
    datePickerValue(val) {
      this.birthday = val;
      this.userForm.birthday = val;
    },
    closeDialog() {
      this.userForm.helpFlag = "";
      sessionStorage.setItem("needRTD", "0");
      this.dialogVisible = false;
    },
    needRDT(num) {
      this.userForm.helpFlag = num;
      sessionStorage.setItem("needRTD", num);
      this.dialogVisible = false;
    },
    jumpClinicalPreferences() {
      sessionStorage.setItem("isShowRTD", true);
      const href = "/cases/clinicalPreferences";
      window.open(href, "_blank");
    },
    getProductList(type, data) {
      if (type === "2") {
        this.getCommonAddProductList({
          platformType: "0",
          // clinicId: this.details.clinicId,
          type,
          caseId: this.details["id"]
        }).then(() => {
          this.productList = this.commonAddProductList.list;
        });
      } else {
        let params;
        params = {
          platformType: "0",
          type
        };
        // if (this.details.cureType !== "F") {
        //   params = pickBy({
        //     clinicId: this.problemList.length ? this.details.clinicId : "",
        //     platformType: "0",
        //     type
        //   });
        // } else {
        //   params = {
        //     platformType: "0",
        //     type
        //   };
        // }
        if (
          this.details["reopenFlag"] === "1" ||
          this.sumProblemList.length ||
          this.details["type"] === "1"
        ) {
          params.caseId = this.details["id"];
        }
        optionalListByProduct(params).then(data => {
          const obj = {};
          const current = data.filter(it => {
            obj[it.id] = {
              name: it.name
            };
            return it.id === this.$route.query.productId;
          });
          if (
            Array.isArray(current) &&
            !["5", "6"].includes(current[0].systemType)
          ) {
            this.productType = current[0].cureType;
          }
          this.productMsgObj = obj;
          setTimeout(() => {
            this.$emit("getProductMsg", obj[this.userForm.productId] || null);
          }, 0);

          this.productList = data;
        });
      }
      setTimeout(() => {
        this.isResponse = 0;
      }, 500);
    },
    selectClinicId(_id) {

      let productId =
        this.userForm.bindProductId !== "0" && this.userForm.bindProductId
          ? this.userForm.bindProductId
          : this.userForm.productId;

      if (_id && productId) {
        const clinicItem = this.clinicList.filter(v => v.id === _id)[0];
        const productItem = clinicItem.clinicInfo.clinicProductList
            .filter(
          v => v.productId === productId
        )[0];
        this.dialogVisible = productItem?.rdtId;
      } else {
        this.dialogVisible = false;
      }
    },
    selectedClinic(_id) {
      this.userForm = {
        ...this.userForm,
        province: "",
        city: "",
        area: "",
        address: "",
        receiverMobile: "",
        phoneArea: "",
        receiver: "",
        contactWay: "1"
      };
      this.doctorAddressId = "";
      this.clinicAddressId = "";
      this.doctorAddressInfo = {};
      sessionStorage.setItem("needRTD", "0");
      this.userForm.helpFlag = "";
      this.helpFlag = "";
      this.userForm.receiveType = "1";
      if (_id) {
        this.getClinicList(_id);
        let _productId =
          this.userForm.bindProductId !== "0" && this.userForm.bindProductId
            ? this.userForm.bindProductId
            : this.userForm.productId;
        if (_id && _productId) {
          const clinicItem = this.clinicList.filter(v => v.id === _id)[0];
          const productItem = clinicItem.clinicInfo.clinicProductList
              .filter(
            v => v.productId === _productId
          )[0];

          this.dialogVisible = productItem?.rdtId;
        }
      } else {
        this.clinicAddressList = [];
        this.clinicAddressId = "";
        this.dialogVisible = false;
      }
      this.$emit("getClinicMsg", this.clinicMsgObj?.[this.userForm.clinicId]);
    },
    changeRdt(val) {
      this.userForm.helpFlag = val;
      this.helpFlag = val;
      sessionStorage.setItem("needRTD", val);
    },
    toStep(d, dom) {
      // 10.53
      let { clinicIndex } = this.clickObj;
      const len = Math.floor(this[dom].children("li").length / 4);
      if (d === "0") {
        // 向左
        if (clinicIndex > 0) {
          this.clickObj.clinicIndex -= 1;
        }
      } else {
        if (clinicIndex < len) {
          ++this.clickObj.clinicIndex;
        }
      }
      this[dom].animate(
        {
          marginLeft: -10.53 * this.clickObj.clinicIndex + "rem"
        },
        600
      );
    },
    enterStep(dom) {
      const parentDom = dom === "productListDom" ? "product_box" : "clinic_box";
      const len = this[dom].children("li").length;
      if (len > 3) {
        $(`.${parentDom} .pre_p,.${parentDom} .next_p`)
          .css({
            opacity: 1
          })
          .fadeIn(500);
      }
    },
    leaveStep(dom) {
      const parentDom = dom === "productListDom" ? "product_box" : "clinic_box";
      const len = this[dom].children("li").length;
      if (len > 3) {
        $(`.${parentDom} .pre_p,.${parentDom} .next_p`)
          .css({
            opacity: 0
          })
          .fadeOut(300);
      }
    },
    // 选择省
    changeProvince() {
      const { province } = this.userForm;
      this.theCityList = this.CITY.filter(item => item.id === province)[0].sub;
      this.countyList = [];
      this.userForm = {
        ...this.userForm,
        city: "",
        area: ""
      };
    },
    // 选择市
    changeCity() {
      const { city } = this.userForm;
      this.countyList = this.theCityList.filter(
        item => item.id === city
      )[0].sub;
      this.userForm = {
        ...this.userForm,
        area: ""
      };
    },
    saveData(key) {
      let {
        clinicId,
        receiverMobile,
        birthday,
        mobile,
        realName,
        sex,
        province,
        city,
        area,
        address,
        productId,
        receiveType,
        receiver,
        phoneArea
      } = this.userForm;
      if (key !== 5) {
        if (!receiveType) {
          notifyMsg(this, "warning", "请选择收货类型");
          return Promise.reject(this.$emit("saveError"));
        }
        if (receiveType === "1") {
          if (this.clinicAddressId === "") {
            notifyMsg(this, "warning", this.$t("yssh.qxzysshdz"));
            return Promise.reject(this.$emit("saveError"));
          }
        }
        if (receiveType === "2") {
          if (!receiver) {
            notifyMsg(
              this,
              "warning",
              this.$t("cases.createUser.qtxsjrxm"),
              2000
            );
            return Promise.reject(this.$emit("saveError"));
          }
          if (this.contactWay == 2) {
            if (!phoneArea) {
              notifyMsg(this, "warning", this.$t("birdie.qtxqh"));
              return Promise.reject(this.$emit("saveError"));
            }
            if (!receiverMobile) {
              notifyMsg(this, "warning", this.$t("birdie.qtxdhhm"));
              return Promise.reject(this.$emit("saveError"));
            }
            if (phoneArea && receiverMobile) {
              const areaPhonePass = /^0\d{2,4}$/.test(phoneArea);
              const phonePass = /^\d{7,8}$/.test(receiverMobile);
              if (!areaPhonePass || !phonePass) {
                if (!areaPhonePass) {
                  notifyMsg(this, "warning", this.$t("birdie.qhsryw"));
                  return Promise.reject(this.$emit("saveError"));
                }
                if (!phonePass) {
                  notifyMsg(this, "warning", this.$t("birdie.dhhmsryw"));
                  return Promise.reject(this.$emit("saveError"));
                }
              }
            }
          } else {
            if (
              !receiverMobile ||
              !/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(
                receiverMobile
              )
            ) {
              if (!receiverMobile) {
                notifyMsg(
                  this,
                  "warning",
                  this.$t("cases.createUser.qsrsjrsjhm"),
                  2000
                );
              } else if (
                !/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(
                  receiverMobile
                )
              ) {
                notifyMsg(this, "warning", this.$t("birdie.sjrsjhmyw"));
              }
              return Promise.reject(this.$emit("saveError"));
            }
          }
          if (!province || !city || !area || !address) {
            notifyMsg(
              this,
              "warning",
              this.$t("cases.createUser.qtxssqjdz"),
              2000
            );
            return Promise.reject(this.$emit("saveError"));
          }
        }
        if (receiveType === "3") {
          if (this.doctorAddressId === "") {
            notifyMsg(this, "warning", this.$t("yssh.qxzysshdz"));
            return Promise.reject(this.$emit("saveError"));
          }
        }
        if (
          mobile &&
          !/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(
            mobile
          )
        ) {
          notifyMsg(this, "warning", this.$t("cases.createUser.qsrlxdh"));
          return Promise.reject(this.$emit("saveError"));
        } else if (!realName) {
          notifyMsg(this, "warning", this.$t("cases.createUser.qtxhz"), 2000);
          return Promise.reject(this.$emit("saveError"));
        } else if (!sex) {
          notifyMsg(this, "warning", this.$t("cases.createUser.qtxxb"), 2000);
          return Promise.reject(this.$emit("saveError"));
        } else if (!birthday) {
          notifyMsg(this, "warning", this.$t("cases.createUser.qtxcs"), 2000);
          return Promise.reject(this.$emit("saveError"));
        } else if (!productId) {
          notifyMsg(this, "warning", this.$t("cases.createUser.qxzcp"), 2000);
          return Promise.reject(this.$emit("saveError"));
        } else if (!clinicId) {
          notifyMsg(this, "warning", this.$t("cases.createUser.qxzzs"), 2000);
          return Promise.reject(this.$emit("saveError"));
        }

        if (this.bindProductList.length && !this.userForm.bindProductId) {
          notifyMsg(
            this,
            "warning",
            this.$t("cases.createUser.qsrtcbdcp"),
            2500
          );
          return Promise.reject(this.$emit("saveError"));
        }
      }

      this.isLoading = true;
      this.userForm.birthday = birthday
        ? formatDate(this.userForm.birthday, "yyyy-MM-dd")
        : "";
      this.birthday = birthday
        ? formatDate(this.userForm.birthday, "yyyy-MM-dd")
        : "";
      let obj = {};
      if (receiveType === "2") {
        obj = {
          receiver,
          receiverMobile,
          province,
          city,
          area,
          address,
          contactWay: this.contactWay
        };
      }
      let submitData = extend({}, this.userForm, obj);
      if (receiveType == "1") {
        submitData.receiveAddressId = this.clinicAddressId;
        delete submitData.contactWay;
      }
      if (this.bindProductList?.length) {
        const current = this.productList.find(
          it => it.id === this.userForm.productId
        );
        const bindProductList = current?.bindProductList;
        const currentBind = bindProductList?.find(
          it => it.id === this.userForm.bindProductId
        );
        this.bindProductNumber = currentBind?.number;
        submitData["number"] = currentBind?.number;
      } else {
        delete submitData["number"];
      }
      if (receiveType === "3") {
        submitData.receiveAddressId = this.doctorAddressId;
      }
      if (this.caseId) {
        submitData.id = this.caseId;
        submitData.helpFlag = this.userForm.helpFlag || "0";
        const hasEmptyElement =
          JSON.stringify(submitData).includes('""') ||
          JSON.stringify(submitData).includes("null") ||
          JSON.stringify(submitData).includes("undefined");
        if (hasEmptyElement && key === 5) {
          sessionStorage.setItem("createUserForm", JSON.stringify(submitData));
          return Promise.resolve();
        }
        return editCases(pickBy(submitData))
          .then(() => {
            sessionStorage.setItem(
              "createUserForm",
              JSON.stringify(this.userForm)
            );
            this.isLoading = false;
            changeRouteQuery(this, {
              caseId: this.caseId,
              productType: this.productType
            });
            notifyMsg(this, "success", this.$t("cases.createUser.yzc"), 2500);
            this.$emit("getClinicMsg", this.clinicMsgObj[clinicId]);
            this.$emit("getProductMsg", this.productMsgObj[productId]);
            return Promise.resolve();
          })
          .catch(err => {
            if (err && err.errorCode === "AIS00128") {
              this.userForm.productId = "";
              this.productList = [];
              this.getProductList();
            }
            this.isLoading = false;
            this.$emit("saveError");
            return Promise.reject();
          });
      } else {
        if (!submitData.hasOwnProperty("helpFlag")) {
          submitData.helpFlag = "0";
        }
        submitData.helpFlag = this.userForm.helpFlag || "0";
        submitData.doctorId = getUserId();
        if (key === 5) {
          sessionStorage.setItem(
            "createUserForm",
            JSON.stringify(this.userForm)
          );
          return Promise.resolve();
        }
        return createCases(pickBy(submitData))
          .then(data => {
            sessionStorage.setItem(
              "createUserForm",
              JSON.stringify(this.userForm)
            );
            this.isLoading = false;
            notifyMsg(this, "success", this.$t("cases.createUser.yzc"), 2500);
            this.$emit("getClinicMsg", this.clinicMsgObj[clinicId]);
            this.$emit("getProductMsg", this.productMsgObj[productId]);
            this.caseId = data.id;
            this.caseNumber = data.caseNumber;
            this.details = data;
            changeRouteQuery(this, {
              caseId: data.id,
              curePlanId: data.currentCurePlanId,
              productType: data.cureType
            });
            sessionStorage.setItem("casesRealName", data.userName);
            return Promise.resolve();
          })
          .catch(err => {
            if (err && err.errorCode === "AIS00128") {
              this.userForm.productId = "";
              this.productList = [];
              this.getProductList();
            }
            this.isLoading = false;
            this.$emit("saveError");
            return Promise.reject();
          });
      }
    },
    changeRoute(args) {
      let route = this.$route;
      this.$router.replace({
        name: route.name,
        query: extend({}, route.query, args)
      });
    },
    getProblem(par) {
      return cureProblemList({
        caseId: this.details.id,
        curePlanId: par.currentCurePlanId
      }).then(data => {
        const diagnosisProblem = {};
        data.forEach(item => {
          diagnosisProblem[item.groupNo] = item.content;
        });
        this.diagnosisProblem = diagnosisProblem;
        this.sumProblemList = data;
        this.problemList = filter(data, item => item.groupNo === 0);
        if (this.problemList.length) {
          this.backClinicId = par.clinicId;
        }
      });
    },
    init(id) {
      // getCityList().then(_cityData => {
      if (id) {
        casesDetail(id).then(data => {
          this.details = data;
          this.bindProductNumber = data["number"];
          // this.userForm.bindProductId = data?.bindProductId;
          this.bindProductId = data?.bindProductId;
          if (data.currentCurePlanId) {
            this.getProblem(data).then(() => {
              this.getProductList(data["type"], data);
            });
          } else {
            this.getProductList(data["type"]);
          }
          this.changeRoute({
            productType: this.details.cureType || "A",
            curePlanId: data.currentCurePlanId || ""
          });
          const {
            userInfo,
            productId,
            clinicId,
            doctorId,
            caseNumber,
            receiver,
            helpFlag,
            receiveType,
            bindProductId
          } = data;
          if (data.helpFlag === "1") {
            sessionStorage.setItem("needRTD", "1");
          } else {
            sessionStorage.setItem("needRTD", "0");
          }
          if (data.receiveType === "1") {
            this.clinicAddressId = data.receiveAddressId;
          }
          if (data.receiveType === "3") {
            this.doctorAddressId = data.receiveAddressId;
            this.doctorAddressInfo = data.otherReceiveAddress;
          }
          this.caseNumber = caseNumber;
          this.birthday = new CDate(userInfo.birthday).format("yyyy-MM-dd");
          this.userForm = {
            sex: userInfo.sex,
            birthday: new CDate(userInfo.birthday).format("yyyy-MM-dd"),
            realName: userInfo.realName,
            mobile: userInfo.mobile,
            province: get(data.otherReceiveAddress, "province") || "",
            city: get(data.otherReceiveAddress, "city") || "",
            area: get(data.otherReceiveAddress, "area") || "",
            address: get(data.otherReceiveAddress, "address") || "",
            receiverMobile: get(data.otherReceiveAddress, "phone") || "",
            receiveType,
            bindProductId,
            phoneArea: get(data.otherReceiveAddress, "phoneArea") || "",
            receiver: get(data.otherReceiveAddress, "receiveUser"),
            clinicId,
            doctorId,
            helpFlag
          };

          if (productId !== "0") {
            this.userForm.productId = this.$route.query.productId || productId;
            if (this.userForm.productId !== data.productId) {
              this.userForm.bindProductId = "";
              this.bindProductId = "";
            }
          }
          this.contactWay = get(data.otherReceiveAddress, "contactWay") || "1";
          const { province, city } = this.userForm;
          const list = this.CITY.filter(item => item.id === province);
          if (list.length) {
            this.theCityList = this.CITY.filter(
              item => item.id === province
            )[0].sub;
            this.countyList = this.theCityList.filter(
              item => item.id === city
            )[0].sub;
          }
          this.helpFlag = data.helpFlag;
          if (this.details.clinicId) {
            this.getClinicList(this.details.clinicId);
          }
        });
      } else {
        const _clinicId = JSON.parse(
          sessionStorage.getItem("createUserForm") || ""
        ).clinicId;
        if (_clinicId) {
          this.getClinicList(_clinicId);
        }

        this.getProductList("0");
      }
      // this.CITY = _cityData;
      const createUserForm = sessionStorage.getItem("createUserForm") || "";
      if (createUserForm) {
        this.userForm = {
          ...JSON.parse(createUserForm),
          ...pickBy(this.userForm)
        };
        this.helpFlag = this.userForm.helpFlag;
        // const { province, city } = this.userForm;

        // const list = this.CITY.filter(item => item.id === province);
        // if (list.length) {
        //   this.theCityList = this.CITY.filter(
        //     item => item.id === province
        //   )[0].sub;
        //   this.countyList = this.theCityList.filter(
        //     item => item.id === city
        //   )[0].sub;
        // }
      }
      clinicDoctorList({
        doctorId: getUserId(),
        status: "1"
      })
        .then(data => {
          this.isLoading = false;
          const obj = {};
          this.clinicList = data.map(item => {
            const oItem = item.clinicInfo;
            obj[oItem.id] = {
              name: oItem.name,
              pic: this.$PicPrefix + oItem.frontPicture
            };
            return {
              id: oItem.id,
              name: oItem.name,
              caseNumber: oItem.number,
              address: `${oItem.province}-${oItem.city}-${oItem.area}-${oItem.address}`,
              description: oItem.description,
              frontPicture: this.$PicPrefix + oItem.frontPicture,
              RDTId: get(item, "clinicInfo.rdtId"),
              clinicInfo: item.clinicInfo
            };
          });
          this.userForm.receiveType = this.details.receiveType;
          this.clinicMsgObj = obj;
          if (id) {
            setTimeout(() => {
              this.$emit("getClinicMsg", obj[this.userForm.clinicId] || null);
            }, 0);
          }
          this.selectClinicId(this.details.clinicId || this.userForm.clinicId);
        })
        .catch(() => {
          this.isLoading = false;
        });
      // });
    },
    getMessage() {
      const [res] = AddressParse.parse(this.address);
      if (res.province) {
        this.CITY.forEach(v => {
          if (v.name === res.province) {
            this.userForm.province = v.id;
          }
        });
        if (this.userForm.province) {
          this.theCityList = this.CITY.filter(
            item => item.id === this.userForm.province
          )[0].sub;
          this.countyList = [];
          this.userForm = {
            ...this.userForm,
            city: "",
            area: "",
            address: ""
          };
        }
      }
      if (res.city) {
        this.theCityList.forEach(v => {
          if (v.name === res.city) {
            this.userForm.city = v.id;
          }
        });
        if (this.userForm.city) {
          this.countyList = this.theCityList.filter(
            item => item.id === this.userForm.city
          )[0].sub;
          this.userForm = {
            ...this.userForm,
            area: "",
            address: ""
          };
        }
        if (res.area) {
          this.countyList.forEach(v => {
            if (v.name === res.area) {
              this.userForm.area = v.id;
            }
          });
        }
      }
      if (res.mobile) {
        this.userForm.receiverMobile = res.mobile;
        this.contactWay = "1";
      }
      if (res.phone) {
        let areaPhone = res["phone"].split("-");
        this.contactWay = "2";
        this.userForm.phoneArea = areaPhone[0];
        this.userForm.receiverMobile = areaPhone[1];
      }
      this.userForm.receiver = res.name;
      this.userForm.address = res.details;
    },
    resetMessage() {
      this.address = "";
    }
  },
  components: {
    FullLoading,
    AddressCard,
    AddressEdit
  },
  created() {
    if (this.$route.query.productId) {
      this.userForm.productId = this.$route.query.productId;
    }
    this.isResponse += 1;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.isLoading = true;
    let id = this.queryCaseId;
    this.caseId = id;
    this.init(id);
    this.getSendAddressList();
  },
  mounted() {
    this.clinicListDom = $(".clinic_list");
    this.productListDom = $(".product_list");
  }
};
</script>

<style lang="scss" scoped>
.dia-header {
  width: 100%;
  height: 58px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .close-dia {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .title {
    font-size: 18px;
    color: #333333;
    display: flex;
    align-items: center;

    .add-address {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 24px;
      font-size: 14px;
      color: #fcc80e;

      img {
        width: 24px;
        margin-right: 4px;
      }
    }
  }
}

.dia-content {
  padding: 10px 30px;
  height: 600px;
  overflow-y: auto;

  .no-data {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 184px;
      margin-bottom: 24px;
    }

    span {
      font-size: 14px;
      line-height: 20px;
      color: #999999;
    }
  }

  .address-item {
    margin-bottom: 10px;
  }
}

.dia-footer {
  height: 30px;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.12);
}

/deep/ .address-dia .el-dialog__header {
  padding: 0px;
}

/deep/ .address-dia .el-dialog__body {
  padding: 0px;
}

/deep/ .address-dia .el-dialog {
  border-radius: 8px;
  margin-top: 15vh !important;
}

.doctor-select,
.doctor-select-active {
  cursor: pointer;
  width: 276px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  padding: 0 20px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 32px;
}

.doctor-select {
  background-color: #f4f4f4;
  color: #999999;
}

.doctor-select-active {
  border: 1px solid #bbbbbb;
  color: #333333;
}

.select-text {
  cursor: pointer;
  font-size: 14px;
  color: #fcc80e;
}

.my-el-select {
  /* selecte 框的高度设置，默认是 40px*/
  .el-input__inner {
    height: 0.3rem !important;
  }

  /* 下面设置右侧按钮居中 */
  .el-input__suffix {
    top: 5px;
  }

  .el-input__icon {
    line-height: inherit;
  }

  .el-input__suffix-inner {
    display: inline-block;
  }
}

.date_picker {
  padding: 0 0.19rem;
  width: 2.7rem;
  background-color: #f4f4f4;
}

/deep/ .el-input.is-disabled .el-input__inner {
  height: 0.42rem !important;
}

/deep/ .el-dialog__header {
  padding-top: 70px;
}

/deep/ .el-dialog__title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $main_theme_color_333;
  font-size: 18px;
}

/deep/ .el-dialog__footer {
  padding-top: 0;
}

/deep/ .dialog-footer {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

/deep/ .el-dialog {
  margin-top: 30vh !important;
}

/deep/ .el-dialog__body {
  padding: 0;
}

.rdt_text {
  display: flex;
  justify-content: center;
  color: $main_theme_color_999;
  font-size: 14px;
}

.create_user {
  background-color: #fff;
  border-radius: 0.06rem;
  padding: 0.16rem 0.3rem 2rem;

  .user_head {
    padding-bottom: 0.16rem;
    border-bottom: 0.01rem solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;

    .user_head_left {
      display: flex;
      align-items: center;
      font-size: 0.18rem;

      .vertical_line {
        display: inline-block;
        width: 0.04rem;
        height: 0.26rem;
        background-color: $main_theme_color;
        margin-right: 0.1rem;
      }
    }

    .user_head_p {
      display: flex;
      align-items: center;
      color: $main_theme_color_333;

      .user_head_sp {
        font-size: 0.14rem;
        margin-right: 0.1rem;
      }

      .case_number {
        font-size: 0.2rem;
      }
    }
  }

  .form_ul {
    padding-top: 0.3rem;
    //margin-bottom: 0.51rem;
    font-size: 0.16rem;

    .f_single {
      display: flex;
      align-items: center;
      margin-bottom: 0.3rem;

      .f_single_left {
        margin-right: 0.2rem;
        width: 1.3rem;
        text-align: right;
        float: right;
        color: $main_theme_color_333;
        white-space: nowrap;

        span {
          color: $main_theme_color_red;
          font-size: 0.14rem;
        }
      }

      .f_single_right {
        flex: 1;
        border-radius: 0.02rem;
        color: $main_theme_color_333;
        position: relative;

        input {
          width: 2.74rem;
          height: 0.4rem;
          box-sizing: border-box;
          outline: none;
          padding-left: 0.19rem;
          border: 1px solid #bbbbbb;
          border-radius: 4px;
        }

        .select_box {
          display: flex;
          align-items: center;
          margin-bottom: 0.2rem;

          select {
            width: 2.74rem;
            height: 0.4rem;
            border: none;
            background-color: #f4f4f4;
            padding: 0.1rem 0.19rem;
            box-sizing: border-box;
          }
        }

        .iup_box {
          width: 5.68rem;

          input {
            width: 100%;
            height: 0.42rem;
          }
        }
      }
    }
  }

  .clinic_box,
  .product_box {
    overflow: hidden;
    position: relative;
    margin-bottom: 0.51rem;
  }

  .clinic_list {
    margin-top: 0.3rem;
    padding-left: 30px;
    display: flex;
    flex-flow: row wrap;
    position: relative;
    z-index: 1;

    .clinic_single {
      display: inline-block;
      width: 2.58rem;
      margin-right: 0.64rem;
      margin-bottom: 0.3rem;
      cursor: pointer;
      position: relative;

      .mask_layer {
        width: 2.58rem;
        height: 176px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 8px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .clinic_pic {
        height: 176px;
        margin-bottom: 0.1rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 0.04rem;
        position: relative;

        .clinic_pic_selected {
          display: inline-block;
          width: 0.24rem;
          height: 0.24rem;
          position: absolute;
          right: 10px;
          top: 10px;
          background-size: 100% 100%;
          background-image: url("../../../common/imgs/case/select_clinic.png");
        }
      }

      .clinic_single_p {
        color: $main_theme_color_333;
        font-size: 0.16rem;
        margin-bottom: 0.04rem;
        line-height: 0.2rem;
      }

      .desc_p {
        color: $main_theme_color_333;
        font-size: 0.16rem;
        display: flex;
        height: auto;
      }

      .clinic_desc {
        margin-bottom: 0.2rem;
        width: 73%;

        p {
          height: 0.18rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .clinic_product {
        width: 73%;

        p {
          white-space: normal;
          line-height: 0.2rem;
          margin-top: -0.02rem;
        }
      }

      .veil_box {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        z-index: 9;
      }
    }

    & div:nth-child(4n) {
      margin-right: 0;
    }
  }

  .pre_p,
  .next_p {
    position: absolute;
    top: 0.34rem;
    width: 0.4rem;
    height: 1.64rem;
    cursor: pointer;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: none;
  }

  .pre_p {
    left: 0;
  }

  .next_p {
    right: 0;
  }
}

.dia_problem {
  margin-top: 10px;
  background: #f4f4f4;
  padding: 10px 16px 10px;

  h5 {
    font-size: 0.22rem;
    color: $main_theme_color_333;
    margin-bottom: 10px;
  }

  span {
    margin-right: 0.2rem;
  }

  .pro_box {
    word-break: break-all;
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: $main_theme_color_333;
  }
}

.create_user .form_ul .f_single .f_single_right .select_box select {
  color: $main_theme_color_999 !important;
}

.no_need_btn_en {
  width: 150px !important;
}

.no_need_btn {
  width: 96px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #bbbbbb;
  text-align: center;
}

.need_btn {
  width: 96px;
  height: 36px;
  background: #f2bf3e;
  border-radius: 4px;
}

.message-add {
  margin-top: 20px;
  width: 600px !important;
  border: 1px solid #bbbbbb;
  border-radius: 5px;
  padding: 10px;
}

.message-add::placeholder {
  font-size: 14px;
  line-height: 24px;
  color: rgb(153, 153, 153);
}

.message-parse {
  display: inline-block;
  margin-left: 10px;
  padding-bottom: 10px;
  color: rgb(252, 200, 14);
  cursor: pointer;
}

.message-reset {
  display: inline-block;
  margin-left: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.tip {
  font-size: 14px;
  color: #ed4027;
  letter-spacing: 1px;
}
.rdt_btn {
  width: 96px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #bbbbbb;
  line-height: 36px;
  font-size: 14px;
  color: #333333;
  text-align: center;
  margin-right: 20px;
  cursor: pointer;
  box-sizing: border-box;
  transition: 0.2s all;
}
.rdt_btn_active {
  background: $main_theme_color;
  border: 1px solid $main_theme_color;
  box-sizing: border-box;
  transition: 0.2s all;
}
.icon_question {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-size: cover !important;
  background: url("../../../common/imgs/case/question.png") no-repeat;
  margin-left: 5px;
}
.disabled_txt {
  left: 152px;
  top: 48px;
  font-size: 12px;
  color: #999;
  position: absolute;
}
.el-icon-close {
  font-size: 26px;
  cursor: pointer;
}
.el-icon-close:hover {
  color: $main_theme_color;
}
</style>
